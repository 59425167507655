<template>
  <div>
    <b-row>
      <b-col sm="3">
        <div class="header-tablepage">Send All</div>
        <div class="count-style">
          {{ all }}
        </div>
      </b-col>
      <b-col sm="3" class="border-sm-left">
        <div class="header-tablepage">Sent Successfully</div>
        <div class="count-style">
          {{ success }}
        </div>
      </b-col>
      <b-col sm="3" class="border-sm-left">
        <div class="header-tablepage">Failed To Send</div>
        <div class="count-style">
          {{ fail }}
        </div>
      </b-col>
      <b-col sm="3" class="border-sm-left">
        <div class="header-tablepage">% Access/Use</div>
        <div class="count-style">
          {{ percen }}
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    success: {
      required: false,
      type: Number,
    },
    fail: {
      required: false,
      type: Number,
    },
    percen: {
      required: false,
      type: String,
    },
    all: {
      required: false,
      type: Number,
    },
  },
};
</script>

<style lang="scss" scoped>
.count-style {
  font-size: 25px;
  font-weight: 600;
  color: var(--primary-color);
  margin-top: 15px;
}
@media screen and (min-width: 415px) {
  ::v-deep .border-sm-left {
    border-left: 1px solid #c5c5c5 !important;
  }
}
</style>
