<template>
  <div class="position-relative mb-4">
    <div :class="['text-editor-custom', { error: isValidate }]">
      <label v-if="textFloat">
        {{ textFloat }}
        <span v-if="isRequired" class="text-danger">*</span>
      </label>
      <div id="gjs" ref="editor"></div>
    </div>
    <img :src="img" alt="logo-lang" v-if="img" class="logo-lang" />
    <div v-if="v && v.$error">
      <span class="text-error" v-if="v.required == false">กรุณากรอกข้อมูล</span>
    </div>
  </div>
</template>

<script>
import grapesjs from "grapesjs";
import "grapescss";
import newsletter from "grapesjs-preset-newsletter";
import ckeditor from "grapesjs-plugin-ckeditor";
// import blocksBasic from "grapesjs-blocks-basic";

export default {
  name: "grapes-text-editor",
  props: {
    value: {
      required: false,
      type: String,
    },
    textFloat: {
      required: false,
      type: String,
    },
    placeholder: {
      required: false,
      type: String,
    },
    required: {
      required: false,
      type: Boolean,
    },
    isRequired: {
      required: false,
      type: Boolean,
    },
    pattern: {
      required: false,
      type: String,
    },
    detail: {
      required: false,
      type: String,
    },
    name: {
      required: false,
      type: String,
    },
    isDisplay: {
      required: false,
      type: Boolean,
    },
    rows: {
      required: false,
      type: Number,
    },
    isValidate: {
      required: false,
      type: Boolean,
    },
    maxLength: {
      required: false,
      type: Number,
    },
    img: {
      required: false,
      type: String,
    },
    v: {
      required: false,
      type: Object,
    },
    variableItem: {
      required: false,
    },
    formMain: {
      required: false,
    },
  },
  data() {
    return {
      editor: null,
      file: "",
      type: {
        all: ["image/jpeg", "image/png", "video/mp4"],
        file: ["image/jpeg", "image/png", "application/pdf"],
        pdf: ["application/pdf"],
        png: ["image/png"],
        image: ["image/jpeg", "image/png"],
        video: ["video/mp4"],
        excel: [
          "application/vnd.ms-excel",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ],
      },
      error: "",
      hasError: false,
      hasImage: false,
      imgModal: null,
      msgModal: null,
      isSuccess: false,
    };
  },
  mounted() {
    // let BlockAddVariables = (editor) => {
    //   for (const items of this.variableItem) {
    //     editor.BlockManager.add(`variable-${items.name}`, {
    //       label: items.name,
    //       category: "Variables",
    //       content: {
    //         type: "text",
    //         content: `${items.name}`,
    //         activeOnRender: 1,
    //       },
    //     });
    //   }
    // };

    this.editor = grapesjs.init({
      container: "#gjs",
      fromElement: true,
      storageManager: { type: 0 },
      assetManager: {
        uploadFile: async (ev) => {
          uploadFile(ev);
        },
      },
      plugins: [
        newsletter,
        ckeditor,
        // BlockAddVariables
      ],
      pluginsOpts: {
        [ckeditor]: {
          // options: {
          //   startupFocus: true,
          //   extraAllowedContent: "*(*);*{*}", // Allows any class and any inline style
          //   allowedContent: true, // Disable auto-formatting, class removing, etc.
          //   enterMode: 2, // CKEDITOR.ENTER_BR,
          //   extraPlugins: "sharedspace,justify,colorbutton,panelbutton,font",
          //   toolbar: [
          //     { name: "styles", items: ["Font", "FontSize"] },
          //     ["Bold", "Italic", "Underline", "Strike"],
          //     { name: "paragraph", items: ["NumberedList", "BulletedList"] },
          //     { name: "links", items: ["Link", "Unlink"] },
          //     { name: "colors", items: ["TextColor", "BGColor"] },
          //     { name: "document", items: ["Mode"] },
          //   ],
          // },
        },
      },
    });
    this.editor
      .on("load", async () => {
        await this.editor.setComponents(this.value);
      })
      .on("update", async () => {
        this.$emit("onDataChange", this.editor.getHtml());
      })
      .on("component:selected", async () => {
        let tag = await getSelectedTag();

        const commandToAdd = `open-modal-tag`;
        const commandIcon = "fa fa-tag";
        const selectedComponent = this.editor.getSelected();
        const defaultToolbar = selectedComponent.get("toolbar");

        // check if this command already exists on this component toolbar
        const commandExists = defaultToolbar.some(
          (item) => item.command === commandToAdd
        );

        // if it doesn't already exist, add it
        if (!commandExists) {
          selectedComponent.set({
            toolbar: [
              ...defaultToolbar,
              {
                attributes: {
                  class: commandIcon,
                  title: tag,
                  "data-tooltip-pos": "bottom",
                },
                command: commandToAdd,
              },
            ],
          });
        }
      });

    var pnm = this.editor.Panels;
    var cmdm = this.editor.Commands;
    var modal = this.editor.Modal;
    var am = this.editor.AssetManager;
    // var cmp = this.editor.Components;
    // var sm = this.editor.Selectors;

    // upload image
    const addImage = (src) => {
      am.add({
        src: src,
        // type: 'image',	// image is default
        // 	height: 300,
        // width: 200,
      });
    };

    const uploadFile = async (ev) => {
      const files = ev.dataTransfer ? ev.dataTransfer.files : ev.target.files;
      await [...files].forEach(async (file) => {
        if (file.size > 50000000) {
          this.errorAlert("This file size is too large");
          return;
        }

        if (file) {
          var reader = new FileReader();
          await reader.readAsDataURL(file);
          reader.onload = async () => {
            let im = new Image();
            im.src = reader.result;
            im.onload = async () => {
              // if (im.width !== im.height) {
              //   this.$swal("Please upload image with ratio 1:1 !", {
              //     icon: "info",
              //   });
              // } else {
              var savetoDB = await this.saveImagetoDb(reader.result);
              let result = savetoDB;
              addImage(result);
              // }
            };
          };
        }
      });
    };

    // variables
    pnm.addButton("options", [
      {
        id: `view-modal-variables`,
        label: `Variables`,
        command: `open-modal-variables`,
        attributes: {
          title: `Variables`,
          "data-tooltip-pos": "bottom",
        },
      },
    ]);

    cmdm.add(`open-modal-variables`, async () => {
      await openModalVariables();
    });

    const openModalVariables = () => {
      const pfx = this.editor.getConfig().stylePrefix;
      const container = document.createElement("div");
      container.className = "mt-2";
      modal.setTitle("Variables");

      for (const item of this.variableItem) {
        const btnEdit = document.createElement("button");
        btnEdit.type = 'button';
        btnEdit.innerHTML = item.name;
        btnEdit.className =
          pfx + "btn-prim " + pfx + "btn-import" + " btn-variable mr-2 mb-2";
        btnEdit.onclick = () => {
          addVariables(item.name);

          modal.close();
        };
        container.appendChild(btnEdit);
      }

      modal.setContent(container);
      modal.open();
    };

    const addVariables = (name) => {
      let selected = this.editor.getSelected();
      const { length } = selected.components();
      this.formMain.is_not_variable = 0;
      selected.append(`<span>{{${name}}}</span>`, { at: parseInt(length) });
    };

    // tag
    cmdm.add(`open-modal-tag`, async () => {
      await openModalTag();
    });

    const getSelectedTag = () => {
      let selected = this.editor.getSelected();
      let tag = selected.get("attributes").tag || "";

      return tag;
    };
    const openModalTag = async () => {
      let tag = await getSelectedTag();

      const pfx = this.editor.getConfig().stylePrefix;
      const container = document.createElement("div");
      container.className = "mt-2";
      modal.setTitle("Tag");

      const inputTag = document.createElement("input");
      inputTag.type = "text";
      inputTag.value = tag;
      inputTag.className = "input-tag";

      const btnSave = document.createElement("button");
      btnSave.type = 'button';
      btnSave.innerHTML = "OK";
      btnSave.className =
        pfx + "btn-prim " + pfx + "btn-import " + " ml-3 px-3";
      btnSave.onclick = () => {
        addTag(inputTag.value);

        modal.close();
      };

      container.appendChild(inputTag);
      container.appendChild(btnSave);

      modal.setContent(container);
      modal.open();
    };

    const addTag = async (name) => {
      let selected = this.editor.getSelected();
      await selected.set("attributes", { tag: name });
    };

    // for (const items of this.variableItem) {
    //   cmdm.add(`open-variable-${items.name}`, () => {
    //     let selected = this.editor.getSelected();
    //     const { length } = selected.components();
    //     selected.append(`<span>{{${items.name}}}</span>`,
    //       { at: parseInt(length) }
    //    );
    //   });

    //   pnm.addButton("options", [
    //     {
    //       id: `view-variable-${items.name}`,
    //       label: `${items.name}`,
    //       command: `open-variable-${items.name}`,
    //       attributes: {
    //         title: items.name,
    //         "data-tooltip-pos": "bottom",
    //       },
    //     },
    //   ]);
    // }
  },
  methods: {
    saveImagetoDb: async function (img) {
      var form = {
        Base64String: img,
      };
      await this.$store.dispatch("ActionUploadImage", form);
      var data = await this.$store.state.moduleConnectAPI.stateUploadImage;
      if (data.result == 1) {
        return data.detail;
      } else {
        this.errorAlert(data.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#gjs {
  border: 3px solid #444;
}

/* Reset some default styling */
.gjs-cv-canvas {
  top: 0;
  width: 100%;
  height: 100%;
}
::v-deep .gjs-btn-tag.active {
  background-color: rgba(255, 255, 255, 0.5) !important;
}
::v-deep .input-tag {
  height: 2rem;
  width: 50%;
  margin-bottom: 0.5rem !important;
}
.text-editor-custom {
  color: var(--font-main-color);
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
}
.text-editor-custom.error #gjs {
  border: 2px solid red !important;
}
.text-error {
  color: red;
}
::v-deep .tox-notifications-container {
  display: none !important;
}
.btn-outline {
  background-color: #dbdbdb;
  border-radius: 20px;
  color: #000;
}
.title-msg {
  color: #000;
  font-size: 17px;
}
</style>
